
// @ts-nocheck


export const localeCodes =  [
  "de",
  "en",
  "it"
]

export const localeLoaders = {
  "de": [],
  "en": [],
  "it": []
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "de",
      "iso": "de-DE",
      "name": "Deutsch"
    },
    {
      "code": "en",
      "iso": "en-US",
      "name": "English"
    },
    {
      "code": "it",
      "iso": "it-IT",
      "name": "Italiano"
    }
  ],
  "defaultLocale": "de",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "https://welance.com",
  "dynamicRouteParams": true,
  "customRoutes": "config",
  "pages": {
    "imprint": {
      "de": "/impressum",
      "en": "/imprint",
      "it": "/imprint"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "de",
    "iso": "de-DE",
    "name": "Deutsch",
    "files": []
  },
  {
    "code": "en",
    "iso": "en-US",
    "name": "English",
    "files": []
  },
  {
    "code": "it",
    "iso": "it-IT",
    "name": "Italiano",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
