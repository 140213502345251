<script setup lang="ts">
const props = defineProps({
  error: {
    type: Object,
    default: () => {},
  },
})

const { t } = useI18n()

useHead({
  title:
    props.error.message !== 'Error' ? props.error.message : t('error.default'),
})
</script>

<template>
  <NuxtLayout>
    <Container inner-class="py-[20vh] flex flex-col justify-center">
      <p
        class="text-35 lg:text-55 leading-tight font-medium"
        :data-error-code="error.statusCode"
      >
        {{ error.statusCode }}
      </p>

      <p
        class="text-16 lg:text-28 leading-snug mt-3"
        v-text="error.message !== 'Error' ? error.message : $t('error.default')"
        :data-error-text="
          error.message !== 'Error' ? error.message : $t('error.default')
        "
      />
    </Container>
  </NuxtLayout>
</template>
