import {
  createDirectus,
  readItem,
  readItems,
  rest,
  readSingleton,
  readFile,
} from '@directus/sdk'
import { type Schema } from '~/.directus/generated/client'

export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig()

  // Create directus client with REST support
  const directus = createDirectus<Schema>(runtimeConfig.public.cmsUrl).with(
    rest()
  )
  return {
    provide: {
      directus,
      readItem,
      readItems,
      readSingleton,
      readFile,
    },
  }
})
